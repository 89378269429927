<template>
  <div class="detail">
    <div class="body">
      <div class="list">
        <div class="title">{{ detail.title }}</div>
        <div class="radios-con">
          <van-radio-group v-model="radio" direction="horizontal" disabled>
            <van-radio
              :name="index"
              v-for="(item, index) in options"
              :key="index"
            >
              {{ item.title }}
              <template #icon="props">
                <img
                  class="img-icon"
                  :src="props.checked ? icon.active : icon.normal"
                />
              </template>
            </van-radio>
          </van-radio-group>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { Toast } from "vant";
import LoadList from "@/components/SearchLoadList";
import { getVoteDetail } from "../api";
export default {
  components: {
    LoadList,
  },
  data() {
    return {
      radio: 0,
      options: [],
      detail: {},
      icon: {
        normal: require("@/assets/images/home/noSelect.png"),
        active: require("@/assets/images/home/select.png"),
      },
    };
  },
  created() {
    let id = this.$route.params.id;
    this.init(id);
  },
  methods: {
    init(id) {
      let params = {
        id: id,
      };
      getVoteDetail(params).then((res) => {
        console.log("radio", res);
        this.detail = res.data.vote;
        this.options = res.data.options;
      });
    },
    
  },
};
</script>

<style lang="less" scoped>
.detail {
  width: 100%;
  min-height: 1vh;
  background-color: #f6f6f6;
  box-sizing: border-box;
  .body {
    margin-top: 0.2rem;
    width: 100%;
    padding: 0 0.26rem;
    background-color: #fff;
    .list {
      width: 100%;
      height: 2rem;
      padding: 0.2rem 0.1rem;
      display: flex;
      flex-direction: column;
      justify-content: space-around;
      .title {
        color: #3b93ff;
        font-size: 0.32rem;
      }
      .radios-con {
        font-size: 0.28rem;
      }
    }
  }
  
}
</style>